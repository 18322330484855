<template>
  <div class="votingPerItem_container">
    <img :src="data.cover" class="votingPerItem_avater" />
    <div class="votingPerItem_info">
      <div class="votingPerItem_info_doctor">
        {{ data.doctor_hospital }} {{ data.doctor_name }}
        <!-- <img
          v-if="data.rank == 1 && data.vote_count !== 0"
          :src="require('@/assets/images/patientShare/trophy.png')"
          class="votingPerItem_trophy"
        /> -->
      </div>
      <el-progress
        ref="proRef"
        :text-inside="true"
        :stroke-width="12"
        :show-text="false"
        :percentage="getVotePress()"
      ></el-progress>

      <div class="votingPerItem_attention">
        <img :src="getImage()" class="votingItem_attention_img" />
        <span>{{ data.vote_count }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { ElProgress } from "element-plus";
export default {
  name: "votingPerItem",
  components: {
    ElProgress,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    item() {
      this.loadData();
    },
  },
  methods: {
    loadData() {
      this.data = { ...this.item };
      this.data.vote_count = this.data.vote_count ? this.data.vote_count : 0;
    },
    getVotePress() {
      return (this.data.vote_count / (this.data.totalCount || 1)) * 100;
    },
    getImage() {
      let name = this.data.voteForMeCount ? "attention-1" : "attention";
      return require(`@/assets/images/patientShare/${name}.png`);
    },
  },
};
</script>
<style>
.votingPerItem_container {
  width: 100%;
  margin-bottom: 5.33vw;
  background: #f2f2f3;
  font-size: 3vw;
  border-radius: 2vw;
  display: flex;
  padding-right: 5.33vw;
}
.votingPerItem_avater {
  width: 16vw;
  height: 16vw;
  flex: none;
}
.votingPerItem_info {
  flex: 1;
  margin-left: 2.67vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 3.2vw;
}
.el-progress-bar__inner {
  background-color: unset;
  background-image: linear-gradient(to right, #80b25f, #d8e7cd);
}
.el-progress-bar__outer {
  width: 33.33;
}
.votingPerItem_attention {
  display: flex;
  align-items: center;
  color: #80b25f;
}
.votingItem_attention_img {
  width: 4vw;
  height: 4vw;
  margin-right: 2.67vw;
}
.votingPerItem_info_doctor {
  display: flex;
  align-items: center;
}
.votingPerItem_trophy {
  width: 4.67vw;
  height: 4.67vw;
  margin-left: 2.67vw;
}
</style>
